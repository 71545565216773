export const initialGame = [
    ['a8', 'blackRook', 'white'],
    ['b8', 'blackKnight', 'black'],
    ['c8', 'blackBishop', 'white'],
    ['d8', 'blackQueen', 'black'],
    ['e8', 'blackKing', 'white'],
    ['f8', 'blackBishop', 'black'],
    ['g8', 'blackKnight', 'white'],
    ['h8', 'blackRook', 'black'],
    ['a7', 'blackPawn', 'black'],
    ['b7', 'blackPawn', 'white'],
    ['c7', 'blackPawn', 'black'],
    ['d7', 'blackPawn', 'white'],
    ['e7', 'blackPawn', 'black'],
    ['f7', 'blackPawn', 'white'],
    ['g7', 'blackPawn', 'black'],
    ['h7', 'blackPawn', 'white'],

    ['a6', '', 'white'],
    ['b6', '', 'black'],
    ['c6', '', 'white'],
    ['d6', '', 'black'],
    ['e6', '', 'white'],
    ['f6', '', 'black'],
    ['g6', '', 'white'],
    ['h6', '', 'black'],
    ['a5', '', 'black'],
    ['b5', '', 'white'],
    ['c5', '', 'black'],
    ['d5', '', 'white'],
    ['e5', '', 'black'],
    ['f5', '', 'white'],
    ['g5', '', 'black'],
    ['h5', '', 'white'],
    ['a4', '', 'white'],
    ['b4', '', 'black'],
    ['c4', '', 'white'],
    ['d4', '', 'black'],
    ['e4', '', 'white'],
    ['f4', '', 'black'],
    ['g4', '', 'white'],
    ['h4', '', 'black'],
    ['a3', '', 'black'],
    ['b3', '', 'white'],
    ['c3', '', 'black'],
    ['d3', '', 'white'],
    ['e3', '', 'black'],
    ['f3', '', 'white'],
    ['g3', '', 'black'],
    ['h3', '', 'white'],

    ['a2', 'whitePawn', 'white'],
    ['b2', 'whitePawn', 'black'],
    ['c2', 'whitePawn', 'white'],
    ['d2', 'whitePawn', 'black'],
    ['e2', 'whitePawn', 'white'],
    ['f2', 'whitePawn', 'black'],
    ['g2', 'whitePawn', 'white'],
    ['h2', 'whitePawn', 'black'],

    ['a1', 'whiteRook', 'black'],
    ['b1', 'whiteKnight', 'white'],
    ['c1', 'whiteBishop', 'black'],
    ['d1', 'whiteQueen', 'white'],
    ['e1', 'whiteKing', 'black'],
    ['f1', 'whiteBishop', 'white'],
    ['g1', 'whiteKnight', 'black'],
    ['h1', 'whiteRook', 'white'],
    
]


export const initialGameBlack = [
    ['h1', 'whiteRook', 'white'],
    ['g1', 'whiteKnight', 'black'],
    ['f1', 'whiteBishop', 'white'],
    ['e1', 'whiteKing', 'black'],
    ['d1', 'whiteQueen', 'white'],
    ['c1', 'whiteBishop', 'black'],
    ['b1', 'whiteKnight', 'white'],
    ['a1', 'whiteRook', 'black'],

    ['h2', 'whitePawn', 'black'],
    ['g2', 'whitePawn', 'white'],
    ['f2', 'whitePawn', 'black'],
    ['e2', 'whitePawn', 'white'],
    ['d2', 'whitePawn', 'black'],
    ['c2', 'whitePawn', 'white'],
    ['b2', 'whitePawn', 'black'],
    ['a2', 'whitePawn', 'white'],

    ['h3', '', 'white'],
    ['g3', '', 'black'],
    ['f3', '', 'white'],
    ['e3', '', 'black'],
    ['d3', '', 'white'],
    ['c3', '', 'black'],
    ['b3', '', 'white'],
    ['a3', '', 'black'],

    ['h4', '', 'black'],
    ['g4', '', 'white'],
    ['f4', '', 'black'],
    ['e4', '', 'white'],
    ['d4', '', 'black'],
    ['c4', '', 'white'],
    ['b4', '', 'black'],
    ['a4', '', 'white'],

    ['h5', '', 'white'],
    ['g5', '', 'black'],
    ['f5', '', 'white'],
    ['e5', '', 'black'],
    ['d5', '', 'white'],
    ['c5', '', 'black'],
    ['b5', '', 'white'],
    ['a5', '', 'black'],

    ['h6', '', 'black'],
    ['g6', '', 'white'],
    ['f6', '', 'black'],
    ['e6', '', 'white'],
    ['d6', '', 'black'],
    ['c6', '', 'white'],
    ['b6', '', 'black'],
    ['a6', '', 'white'],

    ['h7', 'blackPawn', 'white'],
    ['g7', 'blackPawn', 'black'],
    ['f7', 'blackPawn', 'white'],
    ['e7', 'blackPawn', 'black'],
    ['d7', 'blackPawn', 'white'],
    ['c7', 'blackPawn', 'black'],
    ['b7', 'blackPawn', 'white'],
    ['a7', 'blackPawn', 'black'],

    ['h8', 'blackRook', 'black'],
    ['g8', 'blackKnight', 'white'],
    ['f8', 'blackBishop', 'black'],
    ['e8', 'blackKing', 'white'],
    ['d8', 'blackQueen', 'black'],
    ['c8', 'blackBishop', 'white'],
    ['b8', 'blackKnight', 'black'],
    ['a8', 'blackRook', 'white'],
    
]